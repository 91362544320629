<template>
  <div class="agency-sales-report">
    <div class="container fluid">
      <h2 class="p-2 mb-4">Agency Sales Report</h2>

      <div class="row align-items-start p-2 mt-4">
        <div class="col-12 sm-col-8">
          <h3 class="mb-2 sm-mb-0">{{ agencySaleIndex.agencyName }}</h3>
        </div>
        <!-- <div class="col-12 sm-col-4 d-flex justify-content-end">
          <fd-button
            class="main medium"
            :isLoading="isReportDownloading"
            loadingEnabled
            icon="fas fa-external-link-alt"
            @click="downloadPDF"
            >Export</fd-button
          >
        </div> -->
      </div>

      <!-- ======================= Agency Key Index ======================== -->
      <div class="row">
        <sales-index-card
          :class="cardCSS"
          :isLoading="isKeyIndexLoading"
          titleClass="uppercase"
          title="Project"
          valuePrefix="RM"
          :value="
            numberFormat(agencySaleIndex.projectTotalSaleAmount, { decimal: 1 })
          "
          :extraValue="`RM ${
            numberWithCommas(
              parseInt(agencySaleIndex.projectTotalSaleAmount)
            ) || 0
          }`"
        >
        </sales-index-card>
        <sales-index-card
          :class="cardCSS"
          :isLoading="isKeyIndexLoading"
          titleClass="uppercase"
          title="Subsales"
          valuePrefix="RM"
          :value="
            numberFormat(agencySaleIndex.subsaleTotalSaleAmount, { decimal: 1 })
          "
          :extraValue="`RM ${
            numberWithCommas(
              parseInt(agencySaleIndex.subsaleTotalSaleAmount)
            ) || 0
          }`"
        >
        </sales-index-card>
        <sales-index-card
          :class="cardCSS"
          :isLoading="isKeyIndexLoading"
          titleClass="uppercase"
          title="Rent"
          valuePrefix="RM"
          :value="
            numberFormat(agencySaleIndex.rentTotalSaleAmount, { decimal: 1 })
          "
          :extraValue="`RM ${
            numberWithCommas(parseInt(agencySaleIndex.rentTotalSaleAmount)) || 0
          }`"
        >
        </sales-index-card>
        <sales-index-card
          :class="cardCSS"
          :isLoading="isKeyIndexLoading"
          titleClass="uppercase"
          title="Turnover"
          valuePrefix="RM"
          :value="numberFormat(agencySaleIndex.turnover, { decimal: 1 })"
          :extraValue="`RM ${
            numberWithCommas(parseInt(agencySaleIndex.turnover)) || 0
          }`"
        >
        </sales-index-card>
      </div>
      <!-- ======================= Branch Sales Table ====================== -->
      <h3 class="p-2 mt-4">Branch Sales</h3>

      <filter-panel
        v-model="isFilterActive"
        bordered
        class="filter mt-2 py-1"
        @clear="clearFilter"
        @submit="filterData"
      >
        <div v-if="isFilterLoading" class="row justify-content-center p-2">
          <spinner></spinner>
        </div>
        <div class="row py-2 sm-px-2">
          <fd-date-picker
            v-model="filter['fromDate']"
            class="col-6 sm-col-6 px-1 mb-2"
            label="From Date"
            name="filterFromDate"
            :max="
              filter['toDate'] === ''
                ? undefined
                : $moment(filter['toDate'])
                    .subtract(1, 'days')
                    .format('YYYY-MM-DD')
            "
            clearable
          ></fd-date-picker>
          <fd-date-picker
            v-model="filter['toDate']"
            class="col-6 sm-col-6 px-1 mb-2"
            label="To Date"
            name="filterFromDate"
            :min="
              filter['fromDate'] === ''
                ? undefined
                : $moment(filter['fromDate'])
                    .add(1, 'days')
                    .format('YYYY-MM-DD')
            "
            clearable
          >
          </fd-date-picker>
        </div>
      </filter-panel>

      <div class="card">
        <vue-good-table
          mode="remote"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
          @on-row-click="toBranchSalesReport"
          :columns="branchSaleTableColumns"
          :rows="branchSaleTableData"
          :totalRows="branchSaleTablePagination.total"
          :isLoading="isTableLoading"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: 30,
            perPageDropdown: perPageOptions,
            dropdownAllowAll: false
          }"
        >
          <div slot="loadingContent">
            <div class="d-flex justify-content-center">
              <spinner></spinner>
            </div>
          </div>
          <div slot="emptystate">
            <no-data message="Data is not available"></no-data>
          </div>
          <div slot="table-actions" class="p-2">
            <button
              :class="{ active: isFilterActive }"
              class="btn toggle"
              @click="filterToggle"
            >
              <i class="fas fa-filter"></i>
            </button>
          </div>
          <!-- Table Buttons -->
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'actions'">
              <button class="btn edit mr-1">
                <i class="fas fa-pen"></i>
              </button>
              <button class="btn delete">
                <i class="fas fa-trash"></i>
              </button>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import vueGoodTable from "@/mixins/vue-good-table/mixin";
import { numberFormat, numberWithCommas } from "@/utils/string";
import SalesReportAPI from "@/api/v1/salesReport";

export default {
  components: {
    SalesIndexCard: () => import("@/components/SalesReport/SalesIndexCard"),
    Spinner: () =>
      import("@/components/GlobalComponents/LoaderComponent/Spinner")
  },
  mixins: [vueGoodTable],
  props: {},
  data: function () {
    return {
      isReportDownloading: false,
      isKeyIndexLoading: false,
      agencySaleIndex: {
        projectTotalSaleAmount: 0,
        subsaleTotalSaleAmount: 0,
        rentTotalSaleAmount: 0,
        turnover: 0,
        agencyName: ""
      },

      isTableLoading: false,
      branchSaleTableData: [],
      branchSaleTablePagination: {
        total: 0
      },
      branchSaleTableColumns: [
        {
          label: "Branch Name",
          field: "branchName",
          sortable: false
        },
        {
          label: "Project",
          field: "projectTotalSaleAmount",
          sortable: false,
          formatFn: (val) => numberWithCommas(Number(val).toFixed(2))
        },
        {
          label: "Subsale",
          field: "subsaleTotalSaleAmount",
          sortable: false,
          formatFn: (val) => numberWithCommas(Number(val).toFixed(2))
        },
        {
          label: "Rent",
          field: "rentTotalSaleAmount",
          sortable: false,
          formatFn: (val) => numberWithCommas(Number(val).toFixed(2))
        },
        {
          label: "Subtotal",
          field: "subtotal",
          sortable: false,
          formatFn: (val) => numberWithCommas(Number(val).toFixed(2))
        }
      ],
      tableParams: {
        page: 1,
        perPage: 30
      },
      isFilterActive: false,
      isFilterLoading: false,
      appliedFilter: {
        fromDate: "",
        toDate: ""
      },
      filter: {
        fromDate: "",
        toDate: ""
      },

      cardCSS: "col-12 sm-col-6 md-col-3 px-1 mb-2 md-mb-0",

      numberFormat,
      numberWithCommas
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      await Promise.all([this.getAgencySales(), this.getBranchSales()]);
    },
    toBranchSalesReport(e) {
      let id = e.row.branchId;
      this.$router.push({ name: "BranchSalesReport", params: { id: id } });
    },
    // ========================= Table related methods =========================
    updateParams(newProps) {
      this.tableParams = Object.assign({}, this.tableParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getBranchSales();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.getBranchSales().then(() => {
        this.$store.commit("setIsLoading", false);
      });
    },
    // ========================= Filter related methods ========================
    filterData() {
      this.tableParams.page = 1;
      this.getAgencySales();
      this.getBranchSales();
    },
    clearFilter() {
      this.tableParams.page = 1;
      this.filter = this._.mapValues(this.filter, () => "");
      this.getAgencySales();
      this.getBranchSales();
    },
    filterToggle() {
      this.isFilterActive = !this.isFilterActive;
    },
    // ============================== API RELATED ==============================
    async getAgencySales() {
      try {
        this.isKeyIndexLoading = true;
        let filteredParam = this.$cleanQueryParam(this.filter);
        let data = await SalesReportAPI.getAgencyIndex({
          queries: {
            ...filteredParam
          }
        });
        this.agencySaleIndex = this._.cloneDeep(data);
        this.isKeyIndexLoading = false;
      } catch (error) {
        this.isKeyIndexLoading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to get agency sales index. Please try again later."
        });
        throw error;
      }
    },
    async getBranchSales() {
      try {
        this.isTableLoading = true;
        let filteredParam = this.$cleanQueryParam(this.filter);
        let data = await SalesReportAPI.getBranchesSales({
          queries: {
            ...filteredParam
          }
        });

        this.branchSaleTableData = this._.cloneDeep(data.data);
        this.branchSaleTablePagination = this._.cloneDeep(data.meta.pagination);

        this.isTableLoading = false;
      } catch (error) {
        this.isTableLoading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to get branches sale data. Please try again later."
        });
        throw error;
      }
    },
    async downloadPDF() {
      try {
        // TODO: export pdf
        this.isReportDownloading = true;
        let filteredParam = this.$cleanQueryParam(this.filter);
        // await reportAPI.downloadReport(this.$route.params.id, {
        //   queries: {
        //     ...filteredParam
        //   }
        // });
        this.isReportDownloading = false;
      } catch (error) {
        this.isReportDownloading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to export report. Please try again later."
        });
      }
    }
  }
};
</script>

<style lang="scss"></style>
