var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"agency-sales-report"},[_c('div',{staticClass:"container fluid"},[_c('h2',{staticClass:"p-2 mb-4"},[_vm._v("Agency Sales Report")]),_c('div',{staticClass:"row align-items-start p-2 mt-4"},[_c('div',{staticClass:"col-12 sm-col-8"},[_c('h3',{staticClass:"mb-2 sm-mb-0"},[_vm._v(_vm._s(_vm.agencySaleIndex.agencyName))])])]),_c('div',{staticClass:"row"},[_c('sales-index-card',{class:_vm.cardCSS,attrs:{"isLoading":_vm.isKeyIndexLoading,"titleClass":"uppercase","title":"Project","valuePrefix":"RM","value":_vm.numberFormat(_vm.agencySaleIndex.projectTotalSaleAmount, { decimal: 1 }),"extraValue":("RM " + (_vm.numberWithCommas(
            parseInt(_vm.agencySaleIndex.projectTotalSaleAmount)
          ) || 0))}}),_c('sales-index-card',{class:_vm.cardCSS,attrs:{"isLoading":_vm.isKeyIndexLoading,"titleClass":"uppercase","title":"Subsales","valuePrefix":"RM","value":_vm.numberFormat(_vm.agencySaleIndex.subsaleTotalSaleAmount, { decimal: 1 }),"extraValue":("RM " + (_vm.numberWithCommas(
            parseInt(_vm.agencySaleIndex.subsaleTotalSaleAmount)
          ) || 0))}}),_c('sales-index-card',{class:_vm.cardCSS,attrs:{"isLoading":_vm.isKeyIndexLoading,"titleClass":"uppercase","title":"Rent","valuePrefix":"RM","value":_vm.numberFormat(_vm.agencySaleIndex.rentTotalSaleAmount, { decimal: 1 }),"extraValue":("RM " + (_vm.numberWithCommas(parseInt(_vm.agencySaleIndex.rentTotalSaleAmount)) || 0))}}),_c('sales-index-card',{class:_vm.cardCSS,attrs:{"isLoading":_vm.isKeyIndexLoading,"titleClass":"uppercase","title":"Turnover","valuePrefix":"RM","value":_vm.numberFormat(_vm.agencySaleIndex.turnover, { decimal: 1 }),"extraValue":("RM " + (_vm.numberWithCommas(parseInt(_vm.agencySaleIndex.turnover)) || 0))}})],1),_c('h3',{staticClass:"p-2 mt-4"},[_vm._v("Branch Sales")]),_c('filter-panel',{staticClass:"filter mt-2 py-1",attrs:{"bordered":""},on:{"clear":_vm.clearFilter,"submit":_vm.filterData},model:{value:(_vm.isFilterActive),callback:function ($$v) {_vm.isFilterActive=$$v},expression:"isFilterActive"}},[(_vm.isFilterLoading)?_c('div',{staticClass:"row justify-content-center p-2"},[_c('spinner')],1):_vm._e(),_c('div',{staticClass:"row py-2 sm-px-2"},[_c('fd-date-picker',{staticClass:"col-6 sm-col-6 px-1 mb-2",attrs:{"label":"From Date","name":"filterFromDate","max":_vm.filter['toDate'] === ''
              ? undefined
              : _vm.$moment(_vm.filter['toDate'])
                  .subtract(1, 'days')
                  .format('YYYY-MM-DD'),"clearable":""},model:{value:(_vm.filter['fromDate']),callback:function ($$v) {_vm.$set(_vm.filter, 'fromDate', $$v)},expression:"filter['fromDate']"}}),_c('fd-date-picker',{staticClass:"col-6 sm-col-6 px-1 mb-2",attrs:{"label":"To Date","name":"filterFromDate","min":_vm.filter['fromDate'] === ''
              ? undefined
              : _vm.$moment(_vm.filter['fromDate'])
                  .add(1, 'days')
                  .format('YYYY-MM-DD'),"clearable":""},model:{value:(_vm.filter['toDate']),callback:function ($$v) {_vm.$set(_vm.filter, 'toDate', $$v)},expression:"filter['toDate']"}})],1)]),_c('div',{staticClass:"card"},[_c('vue-good-table',{attrs:{"mode":"remote","columns":_vm.branchSaleTableColumns,"rows":_vm.branchSaleTableData,"totalRows":_vm.branchSaleTablePagination.total,"isLoading":_vm.isTableLoading,"pagination-options":{
          enabled: true,
          mode: 'pages',
          perPage: 30,
          perPageDropdown: _vm.perPageOptions,
          dropdownAllowAll: false
        }},on:{"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange,"on-row-click":_vm.toBranchSalesReport},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'actions')?_c('span',[_c('button',{staticClass:"btn edit mr-1"},[_c('i',{staticClass:"fas fa-pen"})]),_c('button',{staticClass:"btn delete"},[_c('i',{staticClass:"fas fa-trash"})])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"loadingContent"},slot:"loadingContent"},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('spinner')],1)]),_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('no-data',{attrs:{"message":"Data is not available"}})],1),_c('div',{staticClass:"p-2",attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('button',{staticClass:"btn toggle",class:{ active: _vm.isFilterActive },on:{"click":_vm.filterToggle}},[_c('i',{staticClass:"fas fa-filter"})])])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }