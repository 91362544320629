import axios from "axios";
import { agencyAPI } from "@/api/config";
import { reportError } from "@/utils/error-reporting";
import qsStringify from "qs/lib/stringify";

let defaultQueries = { queries: {}, page: 1, perPage: 30 };

async function getAgencyIndex(q = { queries: {} }) {
    try {
        const response = await callReportKeyIndexApi(
            `${agencyAPI}/report/overall`,
            q
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Get Agency Index`);
        throw error;
    }
}
async function downloadAgencyReport(q = { queries: {} }) {
    try {
        downloadReport(`${agencyAPI}/report/download`, q);
    } catch (error) {
        reportError(error, `[Agency CP] Download Agency Report`);
        throw error;
    }
}

async function getBranchesSales(q = defaultQueries) {
    try {
        const response = await callReportCollectionApi(
            `${agencyAPI}/report/branches`,
            q
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Get Branches Sales`);
        throw error;
    }
}

async function getBranchSubsaleIndex(branchId, q = { queries: {} }) {
    try {
        const response = await callReportKeyIndexApi(
            `${agencyAPI}/report/subsale/branches/${branchId}/overall`,
            q
        );

        return response.data;
    } catch (error) {
        reportError(
            error,
            `[Agency CP] Get Branch Subsales Key Index (ID:${branchId})`
        );
        throw error;
    }
}

async function getBranchSubsales(branchId, q = defaultQueries) {
    try {
        const response = await callReportCollectionApi(
            `${agencyAPI}/report/subsale/branches/${branchId}/sales`,
            q
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Get Branch Subsales (ID:${branchId})`);
        throw error;
    }
}

async function getBranchRentIndex(branchId, q = { queries: {} }) {
    try {
        const response = await callReportKeyIndexApi(
            `${agencyAPI}/report/rent/branches/${branchId}/overall`,
            q
        );

        return response.data;
    } catch (error) {
        reportError(
            error,
            `[Agency CP] Get Branch Rent Key Index (ID:${branchId})`
        );
        throw error;
    }
}

async function getBranchRentSales(branchId, q = defaultQueries) {
    try {
        const response = await callReportCollectionApi(
            `${agencyAPI}/report/rent/branches/${branchId}/sales`,
            q
        );

        return response.data;
    } catch (error) {
        reportError(
            error,
            `[Agency CP] Get Branch Rent Sales (ID:${branchId})`
        );
        throw error;
    }
}

async function getBranchesSalesAgent(branchId, q = defaultQueries) {
    try {
        const response = await callReportCollectionApi(
            `${agencyAPI}/report/branches/${branchId}/agents`,
            q
        );

        return response.data;
    } catch (error) {
        reportError(
            error,
            `[Agency CP] Get branch sales report agent (ID:${branchId})`
        );
        throw error;
    }
}

async function getAgentSubsaleIndex(agentId, q = { queries: {} }) {
    try {
        const response = await callReportKeyIndexApi(
            `${agencyAPI}/report/subsale/agents/${agentId}/overall`,
            q
        );

        return response.data;
    } catch (error) {
        reportError(
            error,
            `[Agency CP] Get Agent Subsales Key Index (ID:${agentId})`
        );
        throw error;
    }
}

async function getAgentSubsales(agentId, q = defaultQueries) {
    try {
        const response = await callReportCollectionApi(
            `${agencyAPI}/report/subsale/agents/${agentId}/sales`,
            q
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Get Agent Subsales (ID:${agentId})`);
        throw error;
    }
}
async function getAgentRentIndex(agentId, q = { queries: {} }) {
    try {
        const response = await callReportKeyIndexApi(
            `${agencyAPI}/report/rent/agents/${agentId}/overall`,
            q
        );

        return response.data;
    } catch (error) {
        reportError(
            error,
            `[Agency CP] Get Agent Rent Key Index (ID:${agentId})`
        );
        throw error;
    }
}

async function getAgentRentSales(agentId, q = defaultQueries) {
    try {
        const response = await callReportCollectionApi(
            `${agencyAPI}/report/rent/agents/${agentId}/sales`,
            q
        );

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Get Agent Rent Sales (ID:${agentId})`);
        throw error;
    }
}

// ================================ UTILS ================================
async function callReportCollectionApi(url, q) {
    try {
        const response = await axios.get(url, {
            params: {
                ...q.queries,
                page: q.page,
                limit: q.perPage
            }
        });

        return response;
    } catch (error) {
        throw error;
    }
}
async function callReportKeyIndexApi(url, q) {
    try {
        const response = await axios.get(url, {
            params: {
                ...q.queries
            }
        });

        return response;
    } catch (error) {
        throw error;
    }
}
async function downloadReport(url, q) {
    try {
        let response = await fetch(`${url}?${qsStringify(q.queries)}`, {
            headers: new Headers({
                Authorization:
                    "Bearer " + localStorage.getItem("auth_tophillsagency_key"),
                "Content-Type": "application/x-www-form-urlencoded"
            })
        });
        response.blob().then((data) => window.open(URL.createObjectURL(data)));
    } catch (error) {
        throw error;
    }
}

export default {
    // Agency
    getAgencyIndex,
    downloadAgencyReport,
    getBranchesSales,
    // Branch
    getBranchSubsaleIndex,
    getBranchSubsales,
    getBranchRentIndex,
    getBranchRentSales,
    getBranchesSalesAgent,
    // Agent
    getAgentSubsaleIndex,
    getAgentSubsales,
    getAgentRentIndex,
    getAgentRentSales
};
